<template>
  <div class="spark-page" v-if="list">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <b-row>
      <b-col class="mb-10 text-center">
        <b-button
            size="md"
            class="ml-5 mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="$spark.$nav(
              linkParamsViewClaim.manage_name,
              linkParamsViewClaim.manage_params,
            )"
            v-html="linkParamsViewClaim.title"
            ></b-button
          >
      </b-col>
    </b-row>
    <div
      class="spark-page-content column-fluid row justify-content-center"
      v-if="list.length === 0"
    >
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-nothing-to-see-here></spark-nothing-to-see-here>
      </div>
    </div>
    <div class="spark-page-content column-fluid row" v-else>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
      <template v-for="(item, index) in list">
        <div
          v-if="item.contact_type === 2"
          class="col col-sm-12 col-md-6 col-lg-6"
          :key="'email_' + index"
        >
          <spark-correspondence-email :item="item"></spark-correspondence-email>
        </div>
        <div
          v-if="item.contact_type === 1"
          class="col col-sm-12 col-md-6 col-lg-6"
          :key="'contact_' + index"
        >
          <spark-correspondence-display-contact-us
            :item="item"
          ></spark-correspondence-display-contact-us>
        </div>
      </template>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
    </div>
  </div>
</template>

<script>
import {
  CLAIM_CORRESPONDENCE_LIST,
  CLAIM_DETAIL,
  PAGE,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_CORRESPONDENCE_LIST,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE,
} from "@/core/services/vars.service";

import SparkNothingToSeeHere from "@/view/spark_components/NothingToSeeHere";
import SparkCorrespondenceEmail from "@/view/spark_components/portal/correspondence/Email";
import SparkCorrespondenceDisplayContactUs from "@/view/spark_components/portal/correspondence/DisplayContactUs";
import SparkPagination from "@/view/spark_components/portal/Pagination";

export default {
  name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_CORRESPONDENCE_LIST,
  short_name: "Claim Correspondence",
  path: "correspondence/list",
  components: {
    "spark-correspondence-display-contact-us": SparkCorrespondenceDisplayContactUs,
    "spark-correspondence-email": SparkCorrespondenceEmail,
    "spark-nothing-to-see-here": SparkNothingToSeeHere,
    "spark-pagination": SparkPagination
  },
  computed: {
    detail(){
      return this.$store.getters[CLAIM_DETAIL];
    },
    list() {
      return this.$store.getters[CLAIM_CORRESPONDENCE_LIST];
    },
    pagination() {
      return this.$store.getters[PAGE + CLAIM_CORRESPONDENCE_LIST];
    },
    paginationTo() {
      return CLAIM_CORRESPONDENCE_LIST;
    },
    linkParamsViewClaim() {
      if (!this.detail) return null;
      return {
        title: "View Claim [" + this.detail.claim_number + "]",
        manage_name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE,
      };
    },
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>
