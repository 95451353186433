<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import {
  CURRENT_USER,
  LOAD_AUTH,
  //VERIFY_AUTH,
  IS_AUTHENTICATED,
  ACCOUNT_DETAIL,
  CLAIM_STATS,
  CLAIM_LIST,
  //CLAIM_DETAIL,
  SET_CLAIM_DETAIL_PK,
  //CLAIM_DOCUMENT_LIST,
  //CLAIM_DOCUMENT_DETAIL,
  CORRESPONDENCE_LIST,
  //CORRESPONDENCE_DETAIL,
  POLICY_LIST,
  //POLICY_DETAIL,
  SET_POLICY_DETAIL_PK,
  PORTAL_DASHBOARD_STATUS,
  ERROR_401
  //PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US,
} from "@/core/services/vars.service";

export default {
  name: "Layout-Portal-Authenticated",
  path: "secure",
  methods: {
    loadData() {
      if (this.$route.name === this.$spark.$config.views.portal.authenticated.dashboard.name) {
        this.$spark.$api(ACCOUNT_DETAIL).catch(() => {});
        this.$spark.$api(PORTAL_DASHBOARD_STATUS).catch(() => {});
        this.$spark.$api(CLAIM_LIST).then(() => {
          // set pk of most recent
          let claim_list = this.$store.getters[CLAIM_LIST];
          if (claim_list && claim_list.length > 0) {
            this.$spark.$dispatch(SET_CLAIM_DETAIL_PK, claim_list[0].id);
            //this.$spark.$api(CLAIM_DETAIL, claim_list[0].id).catch(() => {});
            //this.$spark.$api(CLAIM_DOCUMENT_LIST, claim_list[0].id)
              //.then(() => {
                /*if (this.$store.getters[CLAIM_DOCUMENT_LIST].length > 0) {
                  this.$spark.$api(
                    CLAIM_DOCUMENT_DETAIL,
                    claim_list[0].id,
                    this.$store.getters[CLAIM_DOCUMENT_LIST][0].data.id
                  );
                }*/
              //}).catch(() => {});
          }
        }).catch(() => {});

        this.$spark.$api(CORRESPONDENCE_LIST).then(() => {
          // set pk of most recent
          //let correspondence_list = this.$store.getters[CORRESPONDENCE_LIST];
          /*if (correspondence_list && correspondence_list.length > 0) {
            this.$spark.$api(
              CORRESPONDENCE_DETAIL,
              correspondence_list[0].id
            ).catch(() => {});
          }*/
        }).catch(() => {});
        if (this.$store.getters[CURRENT_USER].$customer) {
          this.$spark.$api(POLICY_LIST).then(() => {
            // set pk of most recent
            let policy_list = this.$store.getters[POLICY_LIST];
            if(policy_list && policy_list.length > 0) {
              this.$spark.$dispatch(SET_POLICY_DETAIL_PK, this.$store.getters[POLICY_LIST][0].data.id);
              /*this.$spark.$api(
                POLICY_DETAIL,
                this.$store.getters[POLICY_LIST][0].data.id
              ).catch(() => {});*/
            }
          }).catch(() => {});
        }
        if (this.$store.getters[CURRENT_USER].$vet) {
          this.$spark.$api(CLAIM_STATS);
        }
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.error(this)
      //this.$set(this, '$spark', this.$spark);
      this.$spark.$dispatch(LOAD_AUTH).then(() => {
        if (this.$store.getters[IS_AUTHENTICATED]) {
          this.loadData();
        } else {
          this.$spark.$portalLogin({error: ERROR_401});
          this.$destroy();
        }
      });
    });
  }
};
</script>
