<template>
  <b-row class="mt-4 mb-4">
    <b-col
      cols="12"
      sm="6"
      md="6"
      lg="6"
      xl="6"
      class="text-left text-sm-right text-md-right text-lg-right text-xl-right pt-4"
    >
      <label
        :for="'question_' + question_name + '_date'"
        class="shift_input_label vue_property_display_label"
      >
        <span v-html="label" class="font-weight-bold"></span>
        <span
          v-html="'<br/>(' + sub_label + ')'"
          class="pt-2"
          v-if="sub_label"
        ></span>
      </label>
    </b-col>
    <b-col cols="10" sm="5" md="5" lg="5" xl="5" class="input_icon_fixed_width">
      <select
        v-if="Choices"
        :id="'question_' + question_name"
        class="select-css text-left"
        v-model="value"
      >
        <option value="null">-- Please Select --</option>
        <option
          v-for="(option, index) in Choices"
          v-bind:value="option.value"
          :key="index"
        >
          {{ option.label }}
        </option>
      </select>
      <i class="fas fa-paw"></i>
    </b-col>
    <b-col cols="2" sm="1" md="1" lg="1" xl="1">
      <i
        class="fas fa-exclamation-circle field_error_icon pt-2"
        v-if="$parent.error_messages.length > 0"
      ></i>
    </b-col>
    <b-col cols="6" class="spark-desktop-spacer"></b-col>
    <b-col
      cols="12"
      sm="6"
      md="6"
      lg="6"
      xl="6"
      class="vue_field_error pt-2"
      v-if="$parent.error_messages.length > 0"
    >
      <ul>
        <li
          v-for="(message, index) in $parent.error_messages"
          :key="index"
          v-html="message"
        ></li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "spark-choice",
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    sub_label: {
      type: String,
      default: null
    },
    question_name: {
      type: String,
      required: true
    },
    choices: {
      type: Array,
      required: true
    },
    watched_by: {
      type: Array,
      required: true
    },
    not_listed: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      value: "null",
      replacement_choices: null,
      replacement_choice_keys: null
    };
  },
  computed: {
    Value() {
      return this.value;
    },
    ChoiceKeyLabel() {
      if (!this.replacement_choice_keys) return null;
      return this.replacement_choice_keys.label;
    },
    ChoiceKeyValue() {
      if (!this.replacement_choice_keys) return null;
      return this.replacement_choice_keys.value;
    },
    Choices() {
      if (this.choices && !this.replacement_choices) {
        return this.choices;
      } else {
        if (!this.ChoiceKeyLabel || !this.ChoiceKeyValue) return [];
        let choices = Array();
        this.replacement_choices.forEach(choice => {
          choices.push({
            label: choice[this.ChoiceKeyLabel],
            value: choice[this.ChoiceKeyValue]
          });
        });
        if (this.not_listed) {
          choices.push({
            label: "Not Listed!",
            value: "not_listed"
          });
        }
        return choices;
      }
    }
  },
  watch: {
    value: function(new_val) {
      this.$spark.init_section = false;
      this.$parent.hasValidationPassed(new_val);
      this.$parent.value = new_val;
    },
    Choices: function() {
      this.value = "null";
    }
  }
};
</script>
