<template>
  <div>
    <div class="row justify-content-md-center">
      <b-modal
        ref="spark-popup-modal"
        centered
        hide-footer
        :title="popupData.title"
      >
        <div class="d-block text-left" v-html="popupData.content">
        </div>
        <b-row>
          <b-col>
            <b-button
              size="md"
              v-html="'OK'"
              class="mt-5 rounded red-bg red-border spark-variant-text-light font-weight-bold float-right"
              @click="closeModal"
            ></b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  RESET_POPUP,
  POPUP_DATA,
} from "@/core/services/vars.service";

export default {
  methods: {
    closeModal() {
      this.$spark.$dispatch(RESET_POPUP);
      this.$refs["spark-popup-modal"].hide();
    },
  },
  computed: {
    popupData() {
      return this.$store.getters[POPUP_DATA];
    }
  },
  watch: {
    popupData: {
      handler: function(popup) {
        if (popup.title !== null && popup.content !== null) {
          this.$refs["spark-popup-modal"].show();
        }
      }, deep: true
    }
  }
};
</script>
