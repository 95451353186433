import $spark from "@/core/services/spark.service";
import {
  CLAIM_STATS,
  CLAIM_LIST,
  CLAIM_CORRESPONDENCE_LIST,
  CLAIM_DETAIL,
  CLAIM_DETAIL_PK,
  SET_CLAIM_DETAIL_PK,
  SET_CLAIM_INFORMATION_COMPONENT,
  CLAIM_INFORMATION_COMPONENT,
  SUBMIT_CLAIM_APPROVE,
} from "@/core/services/vars.service";

const state = {
  detail_pk: null,
  claim_data_component: null
};

const getters = {
  [CLAIM_DETAIL_PK]: state => {
    return state.detail_pk;
  },
  [CLAIM_INFORMATION_COMPONENT]: state => {
    return state.claim_data_component;
  }
};

const actions = {
  [SET_CLAIM_DETAIL_PK](context, payload) {
    $spark.$storage.set(SET_CLAIM_DETAIL_PK, payload);
    context.commit(SET_CLAIM_DETAIL_PK, payload);
    //return $spark.$api(CLAIM_DETAIL);
  },
  [SET_CLAIM_INFORMATION_COMPONENT](context, payload) {
    context.commit(SET_CLAIM_INFORMATION_COMPONENT, payload);
  }
};

const mutations = {
  [SET_CLAIM_DETAIL_PK](state, data) {
    state.detail_pk = data;
  },
  [SET_CLAIM_INFORMATION_COMPONENT](state, data) {
    state.claim_data_component = data;
  }
};


const dynamic = [
  {
    name: CLAIM_STATS,
    url: {
      path: $spark.$urls.$portal.claim.stats
    }
  },
  {
    name: CLAIM_LIST,
    paginated: true,
    url: {
      path: $spark.$urls.$portal.claim.list
    }
  },
  {
    name: CLAIM_CORRESPONDENCE_LIST,
    paginated: true,
    url: {
      path: $spark.$urls.$portal.claim.correspondence,
      replace: {
        "<CLAIM_PK>": CLAIM_DETAIL_PK
      }
    }
  },
  {
    name: CLAIM_DETAIL,
    pk: true,
    url: {
      path: $spark.$urls.$portal.claim.detail,
      replace: {
        "<CLAIM_PK>": CLAIM_DETAIL_PK
      }
    }
  },
  {
    name: SUBMIT_CLAIM_APPROVE,
    method: "post",
    url: {
      path: $spark.$urls.$portal.claim.approve,
      replace: {
        "<CLAIM_PK>": CLAIM_DETAIL_PK
      }
    }
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;
