import $spark from "@/core/services/spark.service";
import {
  ACCOUNT_DETAIL,
  ACCOUNT_PASSWORD_CHANGE_COMPONENT,
  SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT,
  SUBMIT_PASSWORD_CHANGE_FORM,
  PAGE_NAME_PORTAL_ACCOUNT_MANAGE,
  UPLOAD_VET_ONBOARDING_DOCUMENTS,
  SUBMIT_VET_ONBOARDING_FORM,
  VET_ONBOARDING_DETAIL_PK,
  SET_VET_ONBOARDING_DETAIL_PK
} from "@/core/services/vars.service";

const state = {
  password_change_component: null,
  detail_pk: null
};

const getters = {
  [ACCOUNT_PASSWORD_CHANGE_COMPONENT]: state => {
    return state.password_change_component;
  },
  [VET_ONBOARDING_DETAIL_PK]: state => {
    return state.detail_pk;
  },
};

const actions = {
  [SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT](context, payload) {
    context.commit(SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT, payload);
  },
  [SET_VET_ONBOARDING_DETAIL_PK](context, payload) {
    $spark.$storage.set(SET_VET_ONBOARDING_DETAIL_PK, payload);
    context.commit(SET_VET_ONBOARDING_DETAIL_PK, payload);
    //return $spark.$api(CLAIM_DETAIL);
  },
};

const mutations = {
  [SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT](state, data) {
    state.password_change_component = data;
  },
  [SET_VET_ONBOARDING_DETAIL_PK](state, data) {
    state.detail_pk = data;
  },
};

const document_upload = (payload) => {
  return payload.filedata;
}

const dynamic = [
  {
    name: ACCOUNT_DETAIL,
    url: {
      customer: $spark.$urls.$portal.account.customer.detail,
      vet: $spark.$urls.$portal.account.vet.detail
    },
    autoload_on: [PAGE_NAME_PORTAL_ACCOUNT_MANAGE]
  },
  {
    name: SUBMIT_PASSWORD_CHANGE_FORM,
    method: "put",
    url: {
      path: $spark.$urls.$portal.account.change_password,
    }
  },
  {
    name: SUBMIT_VET_ONBOARDING_FORM,
    method: "post",
    auth: false,
    url: {
      path: $spark.$urls.$portal.vet_register,
    },
    //dispatch_on_success: [COMPLAINT_DOCUMENT_LIST, COMPLAINT_DETAIL]
  },
  {
    name: UPLOAD_VET_ONBOARDING_DOCUMENTS,
    method: "post",
    file: true,
    auth: false,
    url: {
      path: $spark.$urls.$portal.vet_upload,
      replace: {
        "<REGISTRATION_PK>": VET_ONBOARDING_DETAIL_PK
      }
    },
    input_data_serializer: document_upload,
    //dispatch_on_success: [COMPLAINT_DOCUMENT_LIST, COMPLAINT_DETAIL]
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;
