<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content pb-15"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col class="col-8">
            <div class="spark-block-title">
              <i
                class="fas fa-at icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <template v-if="onboarding === null">

        <div class="mb-10">
          <b-row class="mt-4 mb-4">
            <b-col class="col-6 text-right pt-4">
              <span class="font-weight-bold">Type your Veterinary Practice name</span>
            </b-col>
            <b-col class="col-5 input_icon_fixed_width">
              <input type="text" v-model="vet_practice_name" class="pl-15" />
              <i class="fas fa-paw"></i>
            </b-col>
            <b-col class="col-1 mt-2">
              <i
                v-if="vet_practice_name.length < 3"
                class="fas fa-exclamation-circle field_error_icon"
              ></i>
            </b-col>
          </b-row>
          <b-row class="justify-content-right">
            <b-col
              v-if="vet_practice_name.length < 3"
              cols="11"
              class="vue_field_error text-right"
            >
              <ul>
                <li>
                  Please type your Veterinary Practice name<br />Must be at least 3 characters
                  long
                </li>
              </ul>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
        </div>

        <div class="mb-10" v-if="vetSearchResults && vetSearchResults.length > 0">
          <b-row class="mt-4 mb-4">
            <b-col class="col-6 text-right pt-4">
              <span class="font-weight-bold"
                >Select your Veterinary Surgery</span
              >
            </b-col>
            <b-col class="col-5 input_icon_fixed_width">
              <select class="select-css" v-model="selected_practice">
                <option value="null">-- Please Select --</option>
                <option
                  v-for="(choice, index) in vetSearchResults"
                  v-bind:value="choice.id"
                  :key="'type_' + index"
                >
                  {{ choice.practice_name }}
                </option>
              </select>
              <i class="fas fa-paw"></i>
            </b-col>
            <b-col class="col-1 mt-2">
              <i
                v-if="selected_practice === 'null'"
                class="fas fa-exclamation-circle field_error_icon"
              ></i>
            </b-col>
          </b-row>
          <b-row class="justify-content-right">
            <b-col
              v-if="selected_practice === 'null'"
              cols="11"
              class="vue_field_error text-right"
            >
              <ul>
                <li>You must select your Veterinary Surgery</li>
              </ul>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
        </div>

        <template v-if="hasSelectedVet">

          <div class="mb-10">
            <b-row class="mt-4 mb-4">
              <b-col class="col-6 text-right pt-4">
                <span class="font-weight-bold">Please enter your first name</span>
              </b-col>
              <b-col class="col-5 input_icon_fixed_width">
                <input type="text" v-model="first_name" class="pl-15" />
                <i class="fas fa-paw"></i>
              </b-col>
              <b-col class="col-1 mt-2">
                <i
                  v-if="first_name.length < 3"
                  class="fas fa-exclamation-circle field_error_icon"
                ></i>
              </b-col>
            </b-row>
            <b-row class="justify-content-right">
              <b-col
                v-if="first_name.length < 3"
                cols="11"
                class="vue_field_error text-right"
              >
                <ul>
                  <li>
                    Please enter your first name<br />Must be at least 3 characters
                    long
                  </li>
                </ul>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
          </div>

          <div class="mb-10">
            <b-row class="mt-4 mb-4">
              <b-col class="col-6 text-right pt-4">
                <span class="font-weight-bold">Please enter your last name</span>
              </b-col>
              <b-col class="col-5 input_icon_fixed_width">
                <input type="text" v-model="last_name" class="pl-15" />
                <i class="fas fa-paw"></i>
              </b-col>
              <b-col class="col-1 mt-2">
                <i
                  v-if="last_name.length < 3"
                  class="fas fa-exclamation-circle field_error_icon"
                ></i>
              </b-col>
            </b-row>
            <b-row class="justify-content-right">
              <b-col
                v-if="last_name.length < 3"
                cols="11"
                class="vue_field_error text-right"
              >
                <ul>
                  <li>
                    Please enter your last name<br />Must be at least 3 characters
                    long
                  </li>
                </ul>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
          </div>

          <div class="mb-10">
            <b-row class="mt-4 mb-4">
              <b-col class="col-6 text-right pt-4">
                <span class="font-weight-bold"
                  >Please enter your email address</span
                >
              </b-col>
              <b-col class="col-5 input_icon_fixed_width">
                <input type="text" v-model="email" class="pl-15" />
                <i class="fas fa-paw"></i>
              </b-col>
              <b-col class="col-1 mt-2">
                <i
                  v-if="!emailIsValid"
                  class="fas fa-exclamation-circle field_error_icon"
                ></i>
              </b-col>
            </b-row>
            <b-row class="justify-content-right">
              <b-col
                v-if="!emailIsValid"
                cols="11"
                class="vue_field_error text-right"
              >
                <ul>
                  <li>Please enter a valid email address<br />name@example.com</li>
                </ul>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
          </div>

          <div class="mb-10">
            <b-row class="mt-4 mb-4">
              <b-col class="col-6 text-right pt-4">
                <span class="font-weight-bold"
                  >Please enter your mobile telephone number</span
                >
              </b-col>
              <b-col class="col-5 input_icon_fixed_width">
                <input type="text" v-model="telephone" class="pl-15" />
                <i class="fas fa-paw"></i>
              </b-col>
              <b-col class="col-1 mt-2">
                <i
                  v-if="!telephoneIsValid"
                  class="fas fa-exclamation-circle field_error_icon"
                ></i>
              </b-col>
            </b-row>
            <b-row class="justify-content-right">
              <b-col
                v-if="!telephoneIsValid"
                cols="11"
                class="vue_field_error text-right"
              >
                <ul>
                  <li>Please enter a valid mobile number<br />077912345678</li>
                </ul>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
          </div>

        </template>

        <b-row class="justify-content-right" v-if="canSubmit">
          <b-col cols="11" class="text-right">
            <b-button
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="submitForm()"
              >Submit</b-button
            >
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>

      </template>

      <template v-else-if="docsToUpload">
        <vet-document 
          v-for="(item, key) in docsToUpload"
          :key="'vet_doc_'+key"
          :item="item" 
          :parent="onboarding.id"
        ></vet-document>
      </template>

    </b-card>
  </div>
</template>

<script>
import validator from "validator";
import VetDocument from "./VetDocument";

import {
  SET,
  LOOKUP_VET_PUBLIC,
  SUBMIT_VET_ONBOARDING_FORM
} from "@/core/services/vars.service";

export default {
  name: "Portal-Public-Vet-Upload-Component",
  short_name: "Veterinary Surgery Upload",
  components: {
    "vet-document": VetDocument,
  },
  data() {
    return {
      vet_practice_name: "",
      first_name: "",
      last_name: "",
      email: "",
      telephone: "",
      selected_practice: "null",
      onboarding: this.$spark.$storage.get("vetOnboarding"),
    };
  },
  methods: {
    searchVet() {
      let payload = {
        name: this.vet_practice_name
      };
      this.$spark.$api(LOOKUP_VET_PUBLIC, payload);
    },
    submitForm() {
      let payload = {
        vet_practice: this.selected_practice,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        telephone: this.telephone,
        reference: "ONB-"
      };
      this.$spark.$api(SUBMIT_VET_ONBOARDING_FORM, payload)
        .then(() => {
          window.scrollTo(0,0);
          this.$spark.$success("Request Received, please upload documentation");
          this.onboarding = this.$store.getters[SUBMIT_VET_ONBOARDING_FORM];
          this.$spark.$storage.set("vetOnboarding", this.onboarding);
          //this.loadDocumentList();
          //this.$spark.$root();
        }).catch(() => {});
    }
  },
  watch: {
    vet_practice_name: function(val) {
      this.$store.commit(SET + LOOKUP_VET_PUBLIC, [])
      if (val.length >= 3) {
        this.searchVet();
      }
    }
  },
  computed: {
    vetSearchResults() {
      if (!this.$store.getters[LOOKUP_VET_PUBLIC]) return [];
      return this.$store.getters[LOOKUP_VET_PUBLIC];
    },
    hasSelectedVet() {
      return this.selected_practice !== "null";
    },
    canSubmit() {
      return (
        this.selected_practice !== "null" &&
        this.first_name.length >= 3 &&
        this.last_name.length >= 3 &&
        this.emailIsValid &&
        this.telephoneIsValid
      );
    },
    emailIsValid() {
      return validator.isEmail(this.email);
    },
    telephoneIsValid() {
      return validator.isMobilePhone(this.telephone, "en-GB");
    },
    docsToUpload() {
      if (!this.onboarding) return false;
      return this.onboarding.documents;
    }
  }
};
</script>
