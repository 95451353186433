<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="fas fa-cloud-upload-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right">Document name</b-col>
        <b-col class="col-8"><i class="fas fa-paw"></i><span v-html="item.name"></span></b-col>
      </b-row>
      <template v-if="item.required === true">
        <b-row class="mb-5 justify-content-center">
          <b-col class="col-8">
            <b-form-file
              ref="spark-file"
              class="spark-file-multiple"
              multiple
              v-model="file"
              :state="Boolean(file)"
              placeholder="Choose file(s) or drop..."
              drop-placeholder="Drop file(s) here..."
            ></b-form-file>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-4"></b-col>
          <b-col class="col-8">
            <div>
              <b-button
                v-if="showButton"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="startUploadProcess"
                >{{ buttonTitle }}</b-button
              >
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row class="mb-5">
          <b-col class="col-4 font-weight-bold text-right">Uploaded</b-col>
          <b-col class="col-8">
            <i class="fas fa-paw"></i>
            <i
              v-if="item.required === false"
              class="fas fa-check-circle green icon-large"
            ></i>
            <i v-else class="fas fa-times-circle red icon-large"></i>
            {{ item.required === false ? "Yes" : "No" }}
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col class="col-4 font-weight-bold text-right"
            >Number of files</b-col
          >
          <b-col class="col-8">
            <i class="fas fa-paw"></i>
            <span class="fa-stack">
              <i
                class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
              ></i>
              <i
                class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
                style="font-size: 0.8em;"
              ></i>
            </span>
            <span class="ml-13">{{ item.files.length }}</span>
          </b-col>
        </b-row>
        <template v-if="Array.isArray(item.files)">
          <b-row class="mb-5" v-for="(link, index) in item.files" :key="index">
            <b-col class="col-4"></b-col>
            <b-col class="col-8">
              <b-button
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="openInNewTab(link)"
                >View File {{ index + 1 }}</b-button
              >
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row class="mb-5">
            <b-col class="col-4"></b-col>
            <b-col class="col-8">
              <b-button
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="openInNewTab(item.data)"
                >View File</b-button
              >
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
  </div>
</template>

<script>

import { UPLOAD_VET_ONBOARDING_DOCUMENTS, SET_VET_ONBOARDING_DETAIL_PK } from "@/core/services/vars.service";

export default {
  name: "Vet-Document",
  short_name: "Vet Document",
  props: {
    item: {
      type: Object,
      required: true
    },
    parent: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      file: null,
      input: null,
    };
  },
  methods: {
    postData() {
      const form_data = new FormData();
      form_data.append("type", this.item.code);
      if (!this.file) return form_data;
      this.file.forEach((f, index) => {
        form_data.append(this.item.code + "[" + index + "]", f);
        /*if (this.modalData.length > 0) {
          let extra_data = {};
          this.modalData.forEach(extra => {
            extra_data[extra.name] = this[extra.name];
          });
          form_data.append(this.item.field_name + "[" + index + "]" + "_meta", JSON.stringify(extra_data));
        }*/
      });
      console.log(form_data);
      return form_data;
    },
    uploadDocument() {
      let params = {
        filedata: this.postData()
      };
      this.$spark.$dispatch(SET_VET_ONBOARDING_DETAIL_PK, this.parent).then(() => {
        this.$spark.$api(UPLOAD_VET_ONBOARDING_DOCUMENTS, params).then(() => {
          let onboarding = this.$store.getters[UPLOAD_VET_ONBOARDING_DOCUMENTS];
          this.$spark.$storage.set("vetOnboarding", onboarding);
          this.$parent.onboarding = onboarding;
          if (onboarding.documents_complete === true) {
            window.scrollTo(0,0);
            this.$spark.$success("Documentation complete, the team will be in touch soon");
          }
        })
      });
    },
    startUploadProcess() {
      this.uploadDocument();
      /*if (this.item.extra.length === 0) {
        this.uploadDocument();
      } else {
        this.uploadDocument();
        //this.openExtraInfoModal();
      }*/
    },
  },
  computed: {
    showButton() {
      if (this.file && this.file.length > 0) {
        return true;
      }
      return false;
    },
    buttonTitle() {
      if (this.file) {
        if (this.file.length === 1) {
          return "Upload Document";
        } else if (this.file.length > 1) {
          return "Upload Documents";
        }
      }
      return false;
    },
  }
};
</script>

<style>
.spark-file-multiple,
.spark-file-multiple label,
.spark-file-multiple label::after,
.spark-file-multiple input {
  height: 100px;
}
</style>
