import {
  POPUP_DATA, SET_POPUP, RESET_POPUP
} from "@/core/services/vars.service";

const state = {
  title: null,
  content: null,
};

const getters = {
  [POPUP_DATA]: state => {
    return state;
  }
};

const actions = {
  [SET_POPUP]({ commit }, payload) {
    commit(SET_POPUP, payload);
  },
  [RESET_POPUP]({ commit }) {
    commit(RESET_POPUP);
  }
};

const mutations = {
  [SET_POPUP](state, payload) {
    state.title = payload.title;
    state.content = payload.content;
  },
  [RESET_POPUP](state) {
    state.title = null;
    state.content = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
