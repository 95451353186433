import Vue from "vue";
import Vuex from "vuex";

import auth from "../spark_api/portal/auth.module";
import htmlFields from "../spark_api/portal/html.module";
import problems from "../spark_api/portal/problems.module";
import lookups from "../spark_api/portal/lookups.module";
import bank_details from "../spark_api/portal/bank-validation.module";
import policy from "../spark_api/portal/policy.module";
import claim from "../spark_api/portal/claim.module";
import claim_form from "../spark_api/portal/claim-form.module";
import claim_document from "../spark_api/portal/claim-document.module";
import correspondence from "../spark_api/portal/correspondence.module";
import account from "../spark_api/portal/account.module";
import complaint from "../spark_api/portal/complaint.module";
import complaint_document from "../spark_api/portal/complaint-document.module";
import dashboard from "../spark_api/portal/dashbboard.module";
import alert from "../spark_api/alert.module";
import popup from "../spark_api/popup.module";
import confirm from "../spark_api/confirm.module";
import loading from "../spark_api/loading.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import menu from "./menu.module";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    htmlFields,
    problems,
    lookups,
    bank_details,
    policy,
    claim,
    claim_form,
    claim_document,
    correspondence,
    account,
    complaint,
    complaint_document,
    dashboard,
    alert,
    popup,
    confirm,
    loading,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    menu
  }
});

export default store;
