<template>
  <div class="spark-page">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <spark-vet-upload-public></spark-vet-upload-public>
  </div>
</template>

<script>

import {
  IS_AUTHENTICATED,
  PAGE_NAME_PORTAL_ACCOUNT_MANAGE
} from "@/core/services/vars.service";

import SparkVetUploadPublic from "@/view/spark_components/portal/VetUploadPublic";

export default {
  name: "Portal-About-Vet-Upload",
  short_name: "Veterinary Surgery Upload",
  path: "vet-upload",
  components: {
    "spark-vet-upload-public": SparkVetUploadPublic
  },
  beforeMount() {
    if (this.$store.getters[IS_AUTHENTICATED]) {
      this.$spark.$nav({name: PAGE_NAME_PORTAL_ACCOUNT_MANAGE});
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>
