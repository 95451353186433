<template>
  <!-- begin:: Header -->
  <component :is="template">
    <div
      id="kt_header"
      ref="kt_header"
      class="spark-portal-header"
      :class="headerClasses"
    >
      <div class="row">
        <div class="col-3">
          <img
            class="spark-portal-header-logo"
            :src="'/media/logos/clients/' + $spark.$config.resources.logo_url"
            :style="$spark.$config.styles.logo"
          />
        </div>
        <div class="col-6 text-left">
          <p class="h2 spark-portal-header-client-name">
            {{ $spark.$config.main_brand }} Customer Portal
          </p>
          <template v-if="showVetHeader">
            <table id="vet_dash">
              <tr>
                  <th class="red">
                    <span class="fa-stack fa-lg vet_header">
                      <i class="far fa-clipboard fa-5x fa-stack-5x red"></i>
                      <i class="fa fa-stack-1x"><p class="red mt-12">{{ claimsActionRequired }}</p></i>
                    </span>
                  </th>
                  <th class="green">
                    <span class="fa-stack fa-lg vet_header">
                      <i class="far fa-clipboard fa-5x fa-stack-5x green"></i>
                      <i class="fa fa-stack-1x"><p class="green mt-12">{{ claimsBeingProcessed }}</p></i>
                    </span>
                  </th>
                  <th class="blue">
                    <span class="fa-stack fa-lg vet_header">
                      <i class="far fa-clipboard fa-5x fa-stack-5x blue"></i>
                      <i class="fa fa-stack-1x"><p class="blue mt-12">{{ claimsClosed }}</p></i>
                    </span>
                  </th>	
                  <th class="orange">
                    <span class="fa-stack fa-lg vet_header">
                      <i class="far fa-clipboard fa-5x fa-stack-5x orange"></i>
                      <i class="fa fa-stack-1x"><p class="orange mt-12">{{ claimsAwaitingCustomer }}</p></i>
                    </span>
                  </th>	
                  <th class="black">
                    <span class="fa-stack fa-lg vet_header">
                      <i class="far fa-clipboard fa-5x fa-stack-5x black"></i>
                      <i class="fa fa-stack-1x"><p class="black mt-12">{{ claimsTotalSubmitted }}</p></i>
                    </span>
                  </th>
              </tr>
              <tr>
                  <td class="red">Action Required</td>
                  <td class="green">Being Processed</td>
                  <td class="blue">Closed Claims</td>	
                  <td class="orange">Awaiting Customer</td>	
                  <td class="black">Total Submitted</td>
              </tr>
            </table>
          </template>
        </div>
        <div
          class="col-3 text-right spark-portal-header-contact-details"
          v-html="$store.getters[CLIENT_CONTACT_DETAILS]"
        ></div>
      </div>
    </div>
  </component>
  <!-- end:: Header -->
</template>

<script>
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
//import SparkStyle from "@/view/spark_layout/portal/wireframe/Style";
//import ClientConfig from "@/core/config/deployment";
import {
  CLIENT_CONTACT_DETAILS,
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE_HEADER,
  CURRENT_USER,
  CLAIM_STATS
} from "@/core/services/vars.service";

export default {
  name: "KTHeader",
  components: {
    //SparkStyle
  },
  data() {
    return {
      template: null
    };
  },
  created() {
    this.CLIENT_CONTACT_DETAILS = CLIENT_CONTACT_DETAILS;
    this.CURRENT_USER = CURRENT_USER;
  },
  mounted() {
    this.$parent.$parent.$forceUpdate();
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  beforeMount() {
    this.template = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_HEADER);
  },
  computed: {

    showVetHeader() {
      return this.$store.getters[CURRENT_USER].$vet;
    },
    claimStats() {
      return this.$store.getters[CLAIM_STATS];
    },
    claimsActionRequired() {
      if (!this.claimStats) return 0;
      return this.claimStats.action_required;
    },
    claimsBeingProcessed() {
      if (!this.claimStats) return 0;
      return this.claimStats.being_processed;
    },
    claimsClosed() {
      if (!this.claimStats) return 0;
      return this.claimStats.closed_claims;
    },
    claimsAwaitingCustomer() {
      if (!this.claimStats) return 0;
      return this.claimStats.awaiting_customer;
    },
    claimsTotalSubmitted() {
      if (!this.claimStats) return 0;
      return this.claimStats.total;
    },


    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.$store.getters["layoutConfig"]("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.$store.getters["getClasses"]("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.$store.getters["getClasses"]("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>

<style scoped>
#vet_dash tr th {
  text-align: center;
  vertical-align: top;
  height: 90px;
}
#vet_dash tr td {
  text-align: center;
  font-weight: bold;
  padding: 5px;
}
.vet_header i p {
  background-color: #ffffff;
  padding: 0px 2px 0px 2px;
  line-height: 1.3;
  border-radius: 5px;
  font-family: Poppins;
  display: inline-block;
}

.red {
    color: #ff0000;
}
.green {
    color: #00b050;
}
.blue {
    color: #00b0f0;
}
.orange {
    color: #ed7d31;
}
.black {
    color: #000000;
}
.spark-portal-header-logo {
  max-height: 200px;
}
.spark-portal-header-client-name {
  margin-top: 30px;
}
.spark-portal-header-contact-details {
  padding-right: 40px;
}
@media (max-width: 991.98px) {
  .spark-portal-header {
    display: none;
  }
}
</style>
