<template>
  <div class="spark-page" v-if="list">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="col-12">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <slide-up-down
            :active="true"
            class="mb-10">
            <b-card
              class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
              header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
            >
              <template #header>
                <b-row>
                  <b-col>
                    <b-row class="spark-block-title">
                      <b-col>
                        <i
                          class="fas fa-tasks icon-extra-large spark-variant-secondary-text mr-4 mt-1 align-top"
                        ></i>
                        <b-row class="spark-section-header-title-step">
                          <b-col
                            cols="12"
                            sm="8"
                            md="8"
                            lg="8"
                            xl="8"
                            class="order-2 order-sm-1 order-md-1 order-lg-1 order-xl-1"
                          >Filter</b-col>
                          
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
              <b-row class="mb-10">
                <b-col class="text-right font-weight-bold pt-4">
                  Claim Status
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="input_icon_fixed_width">
                      <select class="select-css text-left" v-model="filter.status">
                        <option :value="null">-- All --</option>
                        <option
                          v-for="(option, option_key) in claim_status"
                          :key="option_key"
                          :value="option[0]"
                          v-html="option[1]"
                        >
                        </option>
                      </select>
                      <i class="fas fa-paw"></i>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-10">
                <b-col class="text-right font-weight-bold pt-4">
                  Customer Surname
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="input_icon_fixed_width">
                      <input
                        type="text"
                        v-model="filter.customer_surname"
                        class="pl-15"
                      />
                      <i class="fas fa-paw"></i>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-10">
                <b-col class="text-right font-weight-bold pt-4">
                  Customer Postcode
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="input_icon_fixed_width">
                      <input
                        type="text"
                        v-model="filter.customer_postcode"
                        class="pl-15"
                      />
                      <i class="fas fa-paw"></i>
                    </b-col>
                    <b-col cols="6"></b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-10">
                <b-col class="text-right font-weight-bold pt-4">
                  Claim Number
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="input_icon_fixed_width">
                      <input
                        type="text"
                        v-model="filter.claim_reference"
                        class="pl-15"
                      />
                      <i class="fas fa-paw"></i>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-10">
                <b-col class="text-right font-weight-bold pt-4">
                  Policy Number
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="input_icon_fixed_width">
                      <input
                        type="text"
                        v-model="filter.policy_reference"
                        class="pl-15"
                      />
                      <i class="fas fa-paw"></i>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-10">
                <b-col class="text-right font-weight-bold pt-4">
                  Pet Name
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="input_icon_fixed_width">
                      <input
                        type="text"
                        v-model="filter.pet_name"
                        class="pl-15"
                      />
                      <i class="fas fa-paw"></i>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    size="md"
                    v-html="'Clear Filters'"
                    class="mt-5 rounded red-bg red-border spark-variant-text-light font-weight-bold"
                    @click="clearFilters"
                  ></b-button>
                </b-col>
                <b-col>
                  <b-button
                    size="md"
                    v-html="'Apply Filters'"
                    class="mt-5 rounded green-bg green-border spark-variant-text-light font-weight-bold float-right"
                    @click="applyFilters"
                  ></b-button>
                </b-col>
              </b-row>
            </b-card>
          </slide-up-down>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
    <div
      class="spark-page-content column-fluid row justify-content-center"
      v-if="list.length === 0"
    >
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-nothing-to-see-here></spark-nothing-to-see-here>
      </div>
    </div>
    <div class="spark-page-content column-fluid row" v-else>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
      <template v-for="(item, index) in list">
        <div class="col col-sm-12 col-md-6 col-lg-6" :key="'list_' + index">
          <spark-claim-summary :item="item"></spark-claim-summary>
        </div>
      </template>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CLAIM_VET_LIST,
  CLAIM_LIST,
  PAGE
} from "@/core/services/vars.service";

import SparkNothingToSeeHere from "@/view/spark_components/NothingToSeeHere";
import SparkClaimSummary from "@/view/spark_components/portal/claim/ClaimSummary";
import SparkPagination from "@/view/spark_components/portal/Pagination";
import SparkSlideUpDown from "@/view/spark_components/portal/claim/form/Slider";

export default {
  name: PAGE_NAME_PORTAL_CLAIM_VET_LIST,
  short_name: "Claims",
  path: "list",
  menu: true,
  components: {
    "spark-claim-summary": SparkClaimSummary,
    "spark-nothing-to-see-here": SparkNothingToSeeHere,
    "spark-pagination": SparkPagination,
    "slide-up-down": SparkSlideUpDown
  },
  data(){
    return {
      filter: {
        status: null,
        customer_surname: "",
        customer_postcode: "",
        claim_reference: "",
        policy_reference: "",
        pet_name: "",
      },
      claim_status: [
        [1, "Submitted"],
        [2, "Processing"],
        [3, "Awaiting Policy Holder Information"],
        [4, "Awaiting Policy Holder Documentation"],
        [5, "Awaiting Vet Information"],
        [6, "Awaiting Vet Documentation"],
        [8, "Under Review"],
        [9, "Declined"],
        [10, "Settled/Paid"],
        [11, "Awaiting Review"],
        [12, "Claim Approved, Awaiting Payment Authorisation"],
        [13, "Further Policy Holder Information Required"],
        [14, "Referred To Underwriter, Awaiting Response"],
        [15, "Awaiting Customer Approval"],
        [16, "Reopened"],
      ]
    }
  },
  computed: {
    list() {
      return this.$store.getters[CLAIM_LIST];
    },
    pagination() {
      return this.$store.getters[PAGE + CLAIM_LIST];
    },
    paginationTo() {
      return CLAIM_LIST;
    }
  },
  methods: {
    clearFilters(){
      this.filter = {
        status: null,
        customer_surname: "",
        customer_postcode: "",
        claim_reference: "",
        policy_reference: "",
        pet_name: "",
      }
      this.$spark.$api(CLAIM_LIST);
    },
    applyFilters(){
      let payload = {};
      Object.keys(this.filter).forEach(key => {
        if((key === "status" && this.filter.status !== null) || (key !== "status" && this.filter[key].length > 0)) {
          payload[key] = encodeURIComponent(this.filter[key].toString())
        }
      })
      this.$spark.$api(CLAIM_LIST, payload);
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>
