import $spark from "@/core/services/spark.service";
import {
  AUTH_TOKEN,
  AUTH_EXPIRY,
  CURRENT_USER,
  IS_AUTHENTICATED,
  HAS_GROUP,
  HAS_PERMISSION,
  VERIFY_AUTH,
  LOAD_AUTH,
  REFRESH_AUTH,
  LOGIN,
  AUTO_LOGIN,
  LOGOUT,
  PURGE_AUTH,
  SET_USER_TYPE,
  SET_AUTH,
  SET_PASSWORD,
  SET_ERROR,
  SET_PETS_CLAIM_PAGES,
  PETS_CLAIM_LIST_PAGE,
  PETS_CLAIM_CREATE_PAGE,
  PETS_CLAIM_MANAGE_PAGE,
  PETS_CLAIM_UPDATE_PAGE,
  PETS_CLAIM_CORRESPONDENCE_LIST_PAGE,
  SYSTEM_MESSAGE_LOGOUT_GOODBYE
} from "@/core/services/vars.service";

const user_inital_state = {
  id: null,
  username: null,
  email: null,
  first_name: null,
  last_name: null,
  groups: null,
  permissions: null,
  timer: null
}

const state = {
  errors: null,
  user: Object.assign({}, user_inital_state),
  isAuthenticated: false,
  auth_token: null,
  claim_pages: {
    list: null,
    manage: null,
    create: null,
    update: null
  }
};

const getters = {
  [AUTH_TOKEN]: state => {
    if (!state.auth_token) return null;
    return "JWT " + state.auth_token;
  },
  [CURRENT_USER]: state => {
    return state.user;
  },
  [IS_AUTHENTICATED]: state => {
    return state.isAuthenticated;
  },
  [HAS_GROUP]: state => payload => {
    if (state && state.user.hasOwnProperty("groups")) {
      return state.user.groups.includes(payload);
    }
    return false;
  },
  [HAS_PERMISSION]: state => payload => {
    if (state && state.user.hasOwnProperty("permissions")) {
      if (state.user.permissions[0] == "*") return true;
      return state.user.permissions.includes(payload);
    }
    return false;
  },
  [PETS_CLAIM_LIST_PAGE]: state => {
    return state.claim_pages.list;
  },
  [PETS_CLAIM_CREATE_PAGE]: state => {
    return state.claim_pages.create;
  },
  [PETS_CLAIM_MANAGE_PAGE]: state => {
    return state.claim_pages.manage;
  },
  [PETS_CLAIM_UPDATE_PAGE]: state => {
    return state.claim_pages.update;
  },
  [PETS_CLAIM_CORRESPONDENCE_LIST_PAGE]: state => {
    return state.claim_pages.correspondence;
  }
};

const actions = {
  [LOGOUT](context, redirect = true) {
    $spark.$dispatch(PURGE_AUTH).then(() => {
      if (redirect) {
        $spark.$portalLogin({success: SYSTEM_MESSAGE_LOGOUT_GOODBYE});
      }
    });
  }, 
  [LOAD_AUTH](context) {
    return new Promise(resolve => {
      let payload = {
        access: $spark.$storage.get("Auth"),
        user: $spark.$storage.get("User")
      }
      context.commit(LOAD_AUTH, payload);
      context.commit(SET_USER_TYPE);
      if (state.isAuthenticated === true) {
        $spark.$dispatch(SET_PETS_CLAIM_PAGES).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, 
  [VERIFY_AUTH](context, {error=null, dashboard=false}={}) {
    if (state.isAuthenticated === true) {
      $spark.$dispatch(SET_PETS_CLAIM_PAGES).then(() => {
        if (dashboard) {
          $spark.$dashboard();
        }
      });
    } else {
      if (error) {
        $spark.$portalLogin({ error });
      } else {
        $spark.$portalLogin();
      }
    }
  },
  [SET_AUTH](context, payload) { 
    var token = payload.access;
    var user = payload.user;
    $spark.$storage.set("User", user, AUTH_EXPIRY);
    $spark.$storage.set("Auth", token, AUTH_EXPIRY);
    context.commit(SET_AUTH, payload);
    context.commit(SET_USER_TYPE);
  },
  [REFRESH_AUTH]() {
    $spark.$storage.set("User", state.user, AUTH_EXPIRY);
    $spark.$storage.set("Auth", state.auth_token, AUTH_EXPIRY);
  },
  [PURGE_AUTH](context) {
    $spark.$storage.delete("User");
    $spark.$storage.delete("Auth");
    context.commit(PURGE_AUTH);
  },
  [SET_PETS_CLAIM_PAGES](context, payload) {
    context.commit(SET_PETS_CLAIM_PAGES, payload);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, payload) {
    state.errors = {};
    state.isAuthenticated = true;
    state.user = payload.user;
    state.auth_token = payload.access;
  },
  [SET_USER_TYPE](state) {
    if (state.user.groups && state.user.groups.includes("customer")) {
      state.user.$type = "customer";
      state.user.$customer = true;
      state.user.$vet = false;
    }
    if (state.user.groups && state.user.groups.includes("vet")) {
      state.user.$type = "vet";
      state.user.$customer = false;
      state.user.$vet = true;
    }
    //$spark.$user = state.user;
    //$spark.$secure = state.isAuthenticated;
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.errors = {};
    state.auth_token = null;
    Object.assign(state.user, user_inital_state);
  },
  [LOAD_AUTH](state, payload) {
    if (payload.access !== null && payload.user !== null) {
      state.user = payload.user;
      state.auth_token = payload.access;
      state.isAuthenticated = true;
    }
  },
  [SET_PETS_CLAIM_PAGES](state) {
    var comp = $spark.$config.views.portal.authenticated.claim[state.user.$type];
    var page_data = {
      list: null,
      create: null,
      manage: null,
      update: null,
      correspondence: null
    };
    comp.forEach(item => {
      if (item.path === "list") {
        page_data.list = item.name;
      }
      if (item.path === "correspondence/list") {
        page_data.correspondence = item.name;
      }
      if (item.path === "create") {
        page_data.create = item.name;
      }
      if (item.path.startsWith("manage")) {
        page_data.manage = item.name;
      }
      if (item.path.startsWith("update")) {
        page_data.update = item.name;
      }
    });
    state.claim_pages = page_data;
  }
};


const dynamic = [
  {
    auth: false,
    name: LOGIN,
    method:  "post",
    url: {
      path: $spark.$urls.$portal.auth.login
    },
    commit: false,
    dispatch_on_success: [SET_AUTH, SET_PETS_CLAIM_PAGES],
  },
  {
    auth: false,
    name: AUTO_LOGIN,
    method:  "post",
    url: {
      path: $spark.$urls.$portal.auth.auto_login
    },
    commit: false,
    dispatch_on_success: [SET_AUTH, SET_PETS_CLAIM_PAGES],
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;